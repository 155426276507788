<template>
  <div id="login">
    <div class="container">
      <div class="logincard">
        <div class="panel">
          <div class="panel-heading">
            <img src="/asset/img/login/logo_login.svg">
          </div>
          <div class="panel-body">
            <form class="form-horizontal mb20" @submit.prevent="login">
              <div v-if="errors.email || errors.password" class="alert alert-danger">
                <p v-if="errors.email">
                  {{ errors.email }}
                </p>
                <p v-if="errors.password">
                  {{ errors.password }}
                </p>
              </div>
              <div class="input-group mb10">
                <span class="input-group-addon"><i class="fa fa-envelope-o fa-fw" /></span>
                <input v-model="email" type="text" name="email" class="form-control" placeholder="メールアドレス" autofocus>
              </div>
              <div class="input-group mb5">
                <span class="input-group-addon"><i class="fa fa-key fa-fw" /></span>
                <input v-model="password" type="password" name="password" class="form-control" placeholder="パスワード">
              </div>
              <p class="mb20">
                <router-link
                  :to="{name: 'PreResetPassword', params: {}}"
                  class="f11"
                >
                  <i class="fa fa-info pr5" />パスワードをお忘れの方
                </router-link>
              </p>
              <div class="loginbt">
                <button class="btn btn03" type="submit">
                  LOGIN
                </button>
              </div>
            </form>
            <router-link :to="{name: 'PreRegister'}" class="text-center block">
              <i class="fa fa-angle-double-right pr5" />初めてご利用される方はこちら
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserMeApi, loginApi } from '../../services/api'
import { actions as actionsUser } from '../../store/modules/user'
import { actions as actionsApp } from '../../store/modules/app'
import messageUtils from '../../shared/mixins/messageUtils'
import store from '../../store'

export default {
  name: 'Login',
  mixins: [messageUtils],
  data() {
    return {
      email: '',
      password: ''
    }
  },
  mounted() {
    this.clearMessage()
  },
  methods: {
    async login() {
      await store.dispatch(actionsApp.SET_LOADING, { active: true })
      const res = await loginApi({
        email: this.email,
        password: this.password
      })
      await store.dispatch(actionsApp.SET_LOADING, { active: false })
      if (res.status === 200) {
        this.clearMessage()
        await store.dispatch(actionsUser.LOGIN, res.data)
        await this.getUserMe()
      } else {
        this.updateMessage(res.status, res.data)
      }
    },
    async getUserMe() {
      await store.dispatch(actionsApp.SET_LOADING, { active: true })
      const res = await getUserMeApi()
      await store.dispatch(actionsApp.SET_LOADING, { active: false })
      if (res.status === 200) {
        this.$store.state.user.userInfo = res.data
        const user = res.data
        if (user.is_superuser) {
          await this.$router.push({
            name: 'ListUser'
          })
        } else {
          await this.$router.push({
            name: 'SelectMenu'
          })
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>

.logincard {
  top: 45%;
}
.f11 {
  text-align: left;
}
</style>
